
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinConfigOld from "@/mixins/mixinConfigOld";
  import CommonProgress from "@/components/common/Progress.vue";

  @Component({
    components: {
      CommonProgress,
    },
  })
  export default class Main extends Mixins(mixinConfigOld) {
    get source_file() {
      console.log(this.template_each.pdf);
      return this.template_each.pdf;
    }
    get is_app() {
      const app_token = util.getLocalStorage("app_token");
      return app_token == null ? 0 : 1;
    }
  }
