
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinConfigOld from "@/mixins/mixinConfigOld";
  import CommonProgress from "@/components/common/Progress.vue";
  import CommonButton from "@/components/common/Button.vue";
  import DocumenttemplateInput from "@/components/documenttemplate/Input.vue";
  import DocumenttemplateDisplay from "@/components/documenttemplate/Display.vue";
  import CommonBigbutton from "@/components/common/Bigbutton.vue";

  @Component({
    components: {
      CommonProgress,
      CommonButton,
      DocumenttemplateInput,
      DocumenttemplateDisplay,
      CommonBigbutton,
    },
  })
  export default class Main extends Mixins(mixinConfigOld) {
    created() {
      if (this.is_add) {
        this.m.setDocumentTemplate({
          document_template: {
            name: null,
            content: null,
            auth_employee: 0,
            auth_branch: 1,
            auth_branch_edit: 0,
            hiring_type: null,
            type: 0,
          },
        });
      }
    }

    get document_template() {
      if (this.template_each) {
        return this.template_each.document_template;
      }
    }

    edit_document_template() {
      this.tmp_document_template = util.deep_copy(this.document_template);
      this.m.setEditDocumentTemplateFlag(this.document_template_id);
    }
    delete_document_template() {
      this.m.deleteDocumentTemplate({ document_template_id: this.document_template_id });
    }
    update_document_template() {
      this.m.updateDocumentTemplate({
        document_template_id: this.document_template_id,
        old_document_template: this.document_template,
      });
    }
    create_document_template() {
      this.m.createDocumentTemplate();
    }

    get tmp_document_template() {
      return this.m.document_template;
    }

    set tmp_document_template(val) {
      this.m.setDocumentTemplate({ document_template: val });
    }

    get is_add() {
      return this.document_template_id == 0;
    }
  }
