import { Mutation, Action, VuexModule, getModule, Module } from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import router from "@/router";
import * as util from "@/util";
import validate from "@/validations/validate";
import { validationDocumentTemplate } from "@/validations/document_template";
import Vue from "vue";
import { Document_template } from "@/typesold/Document_template";
import dialog from "@/vuex/dialog";
import template from "./template";
import node from "@/vuex/node";

@Module({ dynamic: true, store, name: "config_manager", namespaced: true })
class Company_manager extends VuexModule {
  add_document_template_flag: boolean = false;
  edit_document_template_flag: number = 0;
  document_template: null | Document_template = null;

  @Action({ rawError: true })
  public async is_open_input() {
    return this.add_document_template_flag || this.edit_document_template_flag;
  }

  @Action({ rawError: true })
  public cancelAll() {
    this.setAddDocumentTemplateFlag(false);
    this.setEditDocumentTemplateFlag(0);
  }

  //DocumentTemplate
  @Mutation
  public setAddDocumentTemplateFlag(val: boolean) {
    this.add_document_template_flag = val;
  }
  @Mutation
  public setEditDocumentTemplateFlag(val: number) {
    this.edit_document_template_flag = val;
  }
  @Mutation
  setDocumentTemplate({ document_template }: { document_template: Document_template }) {
    Vue.set(this, "document_template", document_template);
  }

  @Action({ rawError: true })
  public createDocumentTemplate() {
    if (validate(validationDocumentTemplate(this.document_template))) {
      dialog.openConfirmDialog({
        msg: "書類テンプレートを作成してよろしいですか？",
        cancel_button: "作成しない",
        exec_button: "作成する",
        func: this.createDocumentTemplateProcess,
        args: {},
      });
    }
  }
  @Action({ rawError: true })
  public async createDocumentTemplateProcess({}) {
    template.createDocumentTemplate({ document_template: this.document_template });
    var res = await util.post("config/create_document_template", {
      document_template: this.document_template,
    });
    if (res) {
      this.cancelAll();

      router.replace({ name: "document" });
      node.disable();
      dialog.openAlertDialog({ msg: "作成が完了しました" });
    }
  }
  @Action({ rawError: true })
  public updateDocumentTemplate({
    document_template_id,
    old_document_template,
  }: {
    document_template_id: number;
    old_document_template: any;
  }) {
    if (validate(validationDocumentTemplate(this.document_template))) {
      if (old_document_template.type == 1 && this.document_template.type == 0) {
        //合意が必要から通常に変更
        dialog.openForceConfirmDialog({
          msg: "合意が必要な書類から通常の書類に変更しようとしています。",
          detail:
            "既に送信済み(合意待ち)・合意済み・拒否済みの全ての書類は削除されます。<br>本当によろしいですか？",
          cancel_button: "更新しない",
          exec_button: "更新する",
          func: this.updateDocumentTemplateConfirm,
          is_next_dialog: true,
          args: { document_template_id },
        });
      } else {
        dialog.openConfirmDialog({
          msg: "書類テンプレートを更新してよろしいですか？",
          cancel_button: "更新しない",
          exec_button: "更新する",
          func: this.updateDocumentTemplateProcess,
          args: { document_template_id },
        });
      }
    }
  }
  @Action({ rawError: true })
  public updateDocumentTemplateConfirm({ document_template_id }: { document_template_id: number }) {
    dialog.openForceConfirmDialog({
      msg: "本当によろしいですか？",
      detail:
        "この変更を行うと、送信済み(合意待ち)・合意済み・拒否済みの全ての書類が削除されます。<br>全ての書類は元に戻すことができません。<br>本当によろしいですか？",
      cancel_button: "削除しない",
      exec_button: "削除する",
      func: this.updateDocumentTemplateConfirmForce,
      is_next_dialog: true,
      args: { document_template_id },
    });
  }
  @Action({ rawError: true })
  public updateDocumentTemplateConfirmForce({
    document_template_id,
  }: {
    document_template_id: number;
  }) {
    dialog.openForceConfirmDialog({
      msg: "本当に変更してよろしいですか？",
      detail:
        "この変更を行うと、送信済み(合意待ち)・合意済み・拒否済みの全ての書類が削除されます。<br>全ての書類は元に戻すことができません。<br>本当によろしいですか？",
      cancel_button: "削除しない",
      exec_button: "削除する",
      func: this.updateDocumentTemplateProcess,
      args: { document_template_id },
    });
  }
  @Action({ rawError: true })
  public async updateDocumentTemplateProcess({
    document_template_id,
  }: {
    document_template_id: number;
  }) {
    template.updateDocumentTemplate({
      document_template_id,
      document_template: this.document_template,
    });
    var res = await util.post("config/update_document_template", {
      document_template_id,
      document_template: this.document_template,
    });
    if (res) {
      this.cancelAll();
      router.replace({ name: "document" });
      node.disable();
      dialog.openAlertDialog({ msg: "変更が完了しました" });
    }
  }
  @Action({ rawError: true })
  public deleteDocumentTemplate({ document_template_id }: { document_template_id: number }) {
    dialog.openForceConfirmDialog({
      msg: "書類テンプレートを削除してよろしいですか？",
      detail:
        "一度消したテンプレートは元に戻すことができません。<br>送信済み(合意待ち)・合意済み・拒否済みの全ての書類が削除されます。<br>全ての書類は元に戻すことができません。",
      cancel_button: "削除しない",
      exec_button: "削除する",
      func: this.deleteDocumentTemplateConfirm,
      is_next_dialog: true,
      args: { document_template_id },
    });
  }
  @Action({ rawError: true })
  public deleteDocumentTemplateConfirm({ document_template_id }: { document_template_id: number }) {
    dialog.openForceConfirmDialog({
      msg: "本当によろしいですか？",
      detail:
        "テンプレートだけでなく、送信済み(合意待ち)・合意済み・拒否済みの全ての書類が削除されます。<br>全ての書類は元に戻すことができません。<br>本当によろしいですか？",
      cancel_button: "削除しない",
      exec_button: "削除する",
      func: this.deleteDocumentTemplateConfirmForce,
      is_next_dialog: true,
      args: { document_template_id },
    });
  }
  @Action({ rawError: true })
  public deleteDocumentTemplateConfirmForce({
    document_template_id,
  }: {
    document_template_id: number;
  }) {
    dialog.openForceConfirmDialog({
      msg: "本当に削除してよろしいですか？",
      detail:
        "テンプレートだけでなく、送信済み(合意待ち)・合意済み・拒否済みの全ての書類が削除されます。<br>全ての書類は元に戻すことができません。<br>本当によろしいですか？",
      cancel_button: "削除しない",
      exec_button: "削除する",
      func: this.deleteDocumentTemplateProcess,
      args: { document_template_id },
    });
  }
  @Action({ rawError: true })
  public async deleteDocumentTemplateProcess({
    document_template_id,
  }: {
    document_template_id: number;
  }) {
    template.deleteDocumentTemplate({ document_template_id });
    await util.post("config/delete_document_template", { document_template_id });
    router.replace({ name: "document" });
    node.disable();
    dialog.openAlertDialog({ msg: "削除が完了しました" });
  }
}

export default getModule(Company_manager);
