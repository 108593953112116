import { EmployeeBase, EmployeePayment } from "@/typesold/employee";
import * as util from "@/util";
import * as vali from "@/validations/validationUtil";
import global from "@/vuex/employee_each/global";

//validationは、OKならTrue, それ以外なら警告文を配列に入れる。
export function validationDocumentTemplate(document_template) {
  var error = [];
  error.push(document_template.name !== null || "書類名を入力してください");
  error.push(document_template.auth_branch !== null || "店舗ログインによる使用を選択してください");
  error.push(document_template.auth_employee !== null || "マイページでの使用を選択してください");
  return error;
}
