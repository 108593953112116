import { Component, Vue, Mixins } from "vue-property-decorator";
import base from "@/vuex/config/base";
import allowance from "@/vuex/config/allowance";
import info from "@/vuex/config/info_old";
import template from "@/vuex/config/template";
import template_each from "@/vuex/config/template_each";
import group from "@/vuex/config/group";
import shiftgroup_branchly from "@/vuex/config/shiftgroup_branchly";
import paymentgroup_branchly from "@/vuex/config/paymentgroup_branchly";
import branch from "@/vuex/config/branch";
import branch_bank from "@/vuex/config/branch_bank";
import branch_global from "@/vuex/config/branch_global";
import * as util from "@/util";
import utilMixins from "@/mixins";
import manager from "@/vuex/config/manager_old";
import { BranchHeaderData } from "@/typesold/header";

@Component
export default class mixinConfigOld extends Mixins(utilMixins) {
  get is_set_template(): boolean {
    return template.is_set;
  }
  get is_set_template_each(): boolean {
    return (
      template_each.data[this.document_template_id] &&
      template_each.data[this.document_template_id].is_set
    );
  }

  get is_valid_template(): boolean {
    return template.is_valid;
  }
  get is_valid_template_each(): boolean {
    return (
      template_each.data[this.document_template_id] &&
      template_each.data[this.document_template_id].is_valid
    );
  }

  async get_template() {
    await template.fetch();
  }
  async get_template_each() {
    await template_each.fetch({ document_template_id: this.document_template_id });
  }

  get template() {
    if (!this.is_set_template || !this.is_valid_template) this.get_template();
    if (this.is_set_template) return template.data;
  }
  get template_each() {
    if (!this.is_set_template_each || !this.is_valid_template_each) this.get_template_each();
    if (this.is_set_template_each) return template_each.data[this.document_template_id];
  }

  get m() {
    return manager;
  }

  get document_template_id() {
    return this.query.document_template_id;
  }
}
