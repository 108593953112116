
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import mixinConfigOld from '@/mixins/mixinConfigOld';
import CommonProgress from "@/components/common/Progress.vue";
import DocumenttemplateMain from "@/components/documenttemplate/Main.vue";

@Component({
  components: {
    CommonProgress,
    DocumenttemplateMain,
  }
})
export default class Config extends Mixins(mixinConfigOld) {
}
